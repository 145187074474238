import * as Html from 'BaxterScript/helper/browser/Html';
import { Config } from 'BaxterScript/types/Config';

export const webpackExclude = (config: Config): boolean => !config.app?.scripts?.length;

export const bootstrap = () => {
  const { appId } = globalThis.Baxter.config;
  const { scripts } = globalThis.Baxter.config?.app || {};

  scripts.forEach((script, i) => {
    Html.addScriptToHead(`baxter-script${i}-${appId}`, script.path, script.attributes);
  });
};

export default {
  bootstrap,
};
